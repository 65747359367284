import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";

class Layout extends React.Component {
  render() {
    return (
      <div className="container">
        <Header></Header>
        <Body></Body>
        <Footer></Footer>
      </div>
    );
  }
}

export default Layout;
