const StringKo = {
    //공통
    android: "안드로이드",
    iphone: "아이폰",
    bittiger: "비트타이거톡",
    korean: "한국어",
    english: "영어",
    chinese: "중국어",
    japanese: "일본어",
    vetnam: "베트남어",
    use: "사용하기",
    guide: "이용가이드",
    franchise: "가맹점보기",
    prepare1: "10월 2일 서비스를 시작합니다.",
    prepare2: "앱스토어 출시중입니다.",
  
    //body
    //   첫번째 섹션
    PlatformMain: "Bittiger",
    PlatformSub1: "Blockchain Platform",
    PlatformSub2: "블록체인 기반으로 글로벌 암호화폐를 위한 플랫폼을 실현합니다.",
    PlatformButton: "비트타이거 백서",
    PlatformSub3: "비트타이거는 글로벌 암호화폐들과",
    PlatformSub4: "오프라인 세계를 하나의 플랫폼으로 연결하여 ",
    PlatformSub5: "탈중앙 금융 생태계를 실현합니다.",
  
    //   두번째 섹션
    BusinessMain: "BUSINESS",
    BusinessSub: "비트타이거는 한계를 뛰어 넘습니다.",
    BusinessContent1: "IT 기술의 발달로 전 세계가 연결되어 있지만 한계는 존재합니다.",
    BusinessContent2: "비트타이거는 세 가지 서비스로 이 한계를 뛰어 넘습니다.",
    BusinessDescTitle1: "Bittiger Multi-Wallet",
    BusinessDescContent1: "비트타이거의 모든 서비스를 제공하는 앱",
    BusinessDescTitle2: "Asset Management",
    BusinessDescContent2: "암호화폐 자산을 운용하고 안전하게 보관",
    BusinessDescTitle3: "Global Payment",
    BusinessDescContent3: "전 세계 온오프라인에서 결제할 수 있는 페이",
  
    //   세번째 섹션
    TalkMain: "Bittiger Multi-Wallet",
    TalkSub1: "비트타이거는 다양한 기술의 암호화폐를 수용하고",
    TalkSub2: "다양한 비지니스를 하나의 앱을 통해 제공합니다",
    TalkContent1: "ㆍ 비트타이거를 기축으로 비트코인,이더리움 등 다양한 코인을 하나의 지갑에서 관리",
    TalkContent2: "ㆍ 타이거월렛에서 다른 암호화폐로 P2P 교환",
    TalkContent3: "ㆍ 이더리움, 이오스 등 다양한 블록체인 플랫폼과 연동하여 생태계 확장",
    TalkContent4: "ㆍ 온오프라인에서 간편하게 결제할 수 있는 QR 시스템",
    TalkContent5: "ㆍ 핸드폰의 연락처와 연동한 간편 송금 서비스",
    TalkContent6: "ㆍ 블록체인 트랜잭션 분석을 통한 수수료 최적화",
      
    //   네번째 섹션
    SendEtcMain: "Asset Management",
    SendEtcSub: "거래소 실시간 연동을 통한 자산관리 서비스를 제공합니다",
    SendEtcContent1: "ㆍ 거래소와 API연동으로 실시간 시세 확인",
    SendEtcContent2: "ㆍ 거래소와 API연동으로 실시간 코인 판매",
    SendEtcContent3: "ㆍ 실시간 거래소 시세를 기반으로 한 코인의 교환",
    SendEtcContent4: "ㆍ 코인 스테이킹을 통한 자산운용",
    SendEtcContent5: "ㆍ 멀티시그 적용을 통해 안전한 자산 보관",
  
    //  다섯번째 섹션
    WorldMallMain: "Global Payment",
    WorldMallSub1: "글로벌 페이먼트는 전 세계의 다양한 상품을 온오프라인에서 구매할 수 있는 플랫폼입니다.",
    WorldMallSub2: "물건을 구매하거나 정품인증된 상품을 거래할 수 있습니다.",
    WorldMallBoxTitle1: "Tiger Market",
    WorldMallBoxContent1: "타이거월렛과 제휴된 전 세계 가맹점에서 구매하세요.",
    WorldMallBoxTitle2: "Tiger Exchange",
    WorldMallBoxContent2: "블록체인 정품인증 된 제품을 믿고 거래하세요.",
  
    // 여섯번째 섹션
    RoadMapMain: "우리의 로드맵",
    RoadMapSub1: "각 분야의 전문가로 구성된 팀이 블록체인 분야 기업들과 협력하여",
    RoadMapSub2: "완성도 있는 서비스를 빠르게 만들어 나갑니다.",
    RoadMapDetailDate1: "2020. 3Q",
    RoadMapDetailTitle1: "회사설립",
    RoadMapDetailContent1: "주식회사 해시원 출범",
    RoadMapDetailDate2: "2020. 4Q",
    RoadMapDetailTitle2: "거래소 상장 / 서비스오픈",
    RoadMapDetailContent2: "토큰상장 및 지갑서비스 베타오픈",
    RoadMapDetailDate3: "2021. 1Q",
    RoadMapDetailTitle3: "블록체인 기업제휴",
    RoadMapDetailContent3: "타이거월렛 얼라이언스 구축",
    RoadMapDetailDate4: "2021. 2Q",
    RoadMapDetailTitle4: "지갑서비스 정식오픈",
    RoadMapDetailContent4: "듀얼월렛, SNS, DEX 등 서비스 제공",
    RoadMapDetailDate5: "2021. 3Q",
    RoadMapDetailTitle5: "해외시장 진출",
    RoadMapDetailContent5: "글로벌 플랫폼 출범식",
    RoadMapDetailDate6: "2021. 4Q",
    RoadMapDetailTitle6: "글로벌 서비스 활성화",
    RoadMapDetailContent6: "해외기업 제휴 및 결제플랫폼 오픈",
  
    //   일곱번째 섹션
    TeamMain: "BITTIGER TEAM",
    TeamSub1: "비트타이거를 이끌어 가는 우리의 팀원들입니다.",
    TeamSub2: "블록체인의 가치를 만들어 나갑니다.",
    TeamSubMember01Name: "이일우",
    TeamSubMember01Job: "CEO",
    TeamSubMember01Hist01: "현) With Major CEO",
    TeamSubMember01Hist02: "전) PlanBit CEO",
    TeamSubMember01Hist03: "전) Qmaxschool 이사",
    TeamSubMember02Name: "이재경(Justin)",
    TeamSubMember02Job: "CFO",
    TeamSubMember02Hist01: "현) Bithao cfo",
    TeamSubMember02Hist02: "전) Bitbank 이사",
    TeamSubMember02Hist03: "전) Cryptohub 이사",
    TeamSubMember03Name: "Victor",
    TeamSubMember03Job: "CBO",
    TeamSubMember03Hist01: "현 비트하오 CBO",
    TeamSubMember03Hist02: "전 VMC CSO",
    TeamSubMember03Hist03: "전 카이져 adviser",
    TeamSubMember04Name: "김수빈",
    TeamSubMember04Job: "CMO",
    TeamSubMember04Hist01: "현) bithao 마케팅팀장",
    TeamSubMember04Hist02: "전) 켈리포니아주 캘리페이 어드바이저",
    TeamSubMember05Name: "김민준",
    TeamSubMember05Job: "COO",
    TeamSubMember05Hist01: "전) 블랍스 COO",
    TeamSubMember05Hist02: "전) 잇츠아이티 대표이사",
    TeamSubMember06Name: "김민우",
    TeamSubMember06Job: "CTO",
    TeamSubMember06Hist01: "현) 블릭트 대표이사",
    TeamSubMember06Hist02: "전) 삼성SDS 연구원",
    TeamSubMember07Name: "최우혁",
    TeamSubMember07Job: "CIO",
    TeamSubMember07Hist01: "전) 블랍스 이사",
    TeamSubMember08Name: "고가빈",
    TeamSubMember08Job: "COO",
    TeamSubMember08Hist01: "현) 하이컴퍼니 대표이사",
  
    //  여덟번째 섹션
    PartnersMain: "BITTIGER'S PARTNERS",
  
    //   아홉번째 섹션
    BTTalkMain: "TIGER WALLET",
    BTTalkSub1: "여러분의 자산을 안전하게 보관하고,",
    BTTalkSub2: "스테이킹을 통해 수익을 창출하는 타이거월렛을 지금 시작하세요",
    WhitePaperMain: "WHITE PAPER",
  
    //Footer
    AddressMain:
      "서울특별시 강남구 봉은사로 317",
    CEOinfoMain:
      "사업자등록번호:443-87-01788 | 이메일:admin@bit-tiger.kr",
  };
  export default StringKo;
  