const StringJa = {
    //共通
    android: "アンドロイド",
    iphone: "iPhone",
    bittiger: "ビットタイガートーク",
    korean: "韓国語",
    english: "英語",
    chinese: "中国語",
    japanese: "日本語",
    vetnam: "ベトナム語",
    use: "使用する",
    guide: "利用ガイド",
    franchise: "加盟店を見る",
    prepare1: "10月2日サービスを開始します",
    prepare2: "10月20日サービスを開始します",
   
   //body
   // 1番目のセクション
    PlatformMain: "Bittiger",
    PlatformSub1: "Blockchain Platform",
    PlatformSub2: "ブロックチェーン基盤でグローバル暗号通貨のためのプラットフォームを実現します。",
    PlatformButton: "ビットタイガー白書",
    PlatformSub3: "ビットタイガーはグローバル暗号通貨と",
    PlatformSub4: "オフライン世界を一つのプラットフォームでつなげて",
    PlatformSub5: "脱中央金融生態系を実現します。",
   
    // 2番目のセクション
    BusinessMain: "BUSINESS",
    BusinessSub: "ビットタイガーは限界を飛び越えます。",
    BusinessContent1: "IT技術の発達で、全世界がつながっていますが、限界は存在します。",
    BusinessContent2: "ビットタイガーは3つのサービスで、その限界を飛び越えます。",
    BusinessDescTitle1: "Bittiger Multi-Wallet",
    BusinessDescContent1: "ビットタイガーの全サービスを提供するアプリ",
    BusinessDescTitle2: "Asset Management",
    BusinessDescContent2: "暗号通貨資産を運用し、安全に保管",
    BusinessDescTitle3: "Global Payment",
    BusinessDescContent3: "世界中のオン·オフラインで決済できるペイ",
   
   // 3番目のセクション
    TalkMain:"Bittiger Multi-Wallet",
    TalkSub1: "ビットタイガーは様々な技術の暗号通貨を受け入れて",
    TalkSub2: " 1つのアプリで多様なビジネスを提供します。",
    TalkContent1: "·ビットタイガーを軸にして、ビットコイン、イーサリアム等のいろんなコインを一つのウォレットで管理",
    TalkContent2:"·タイガーウォレットで他の暗号通貨へとP2P交換",
    TalkContent3: "·イーサリアム、イオス等いろんなブロックチェーンプラットフォームと連動して生態系拡張",
    TalkContent4: "·手軽にオン·オフラインで決済できるQRシステム",
    TalkContent5: "·携帯電話の連絡先と連動した簡単送金サービス",
    TalkContent6: "·ブロックチェーントランザクション分析で手数料最適化",
   
    // 4番目のセクション
    SendEtcMain: "Asset Management",
    SendEtcSub: "取引所リアルタイム連動で資産管理サービスを提供します。",
    SendEtcContent1: "·取引所とのAPI連動でリアルタイム相場確認",
    SendEtcContent2: "·取引所とのAPI連動でリアルタイムコイン販売",
    SendEtcContent3: "·リアルタイム取引所相場に基づいたコイン交換",
    SendEtcContent4: "·コインステーキングによる資産運用",
    SendEtcContent5:"·マルチシグ適用で安全な資産保管",
   
   // 5番目のセクション
    WorldMallMain: "Global Payment",
    WorldMallSub1: "グローバルペイメントは、世界中の多様な商品をオン·オフラインで購入できるプラットフォームです。",
    WorldMallSub2: "商品を購入したり、正規品認証された商品を取引することができます。",
    WorldMallBoxTitle1:"Tiger Market",
    WorldMallBoxContent1: "タイガーウォレットと提携している全世界の加盟店でお買い求めください。",
    WorldMallBoxTitle2:"TigerExchange",
    WorldMallBoxContent2: "ブロックチェーン正規品認証された製品を信じてお取引ください。",
   
   // 6番目のセクション
    RoadMapMain: "我々のロードマップ",
    RoadMapSub1: "各分野の専門家で構成されたチームがブロックチェーン分野の企業と協力し」",
    RoadMapSub2: "完成度高いサービスを速やかに作り上げていきます。",
    RoadMapDetailDate1:"2020.3Q",
    RoadMapDetailTitle1: "会社設立",
    RoadMapDetailContent1: "株式会社ハッシュワン発足",
    RoadMapDetailDate2:"2020.4Q",
    RoadMapDetailTitle2: "取引所上場",
    RoadMapDetailContent2: "トークン上場及びウォレットサービスベータオープン",
    RoadMapDetailDate3:"2021.1Q",
    RoadMapDetailTitle3: "ブロックチェーン企業提携",
    RoadMapDetailContent3: "タイガーウォレットアライアンス構築",
    RoadMapDetailDate4:"2021.2Q",
    RoadMapDetailTitle4: "ウォレット正式オープン",
    RoadMapDetailContent4: "デュアルウォレット、SNS、DEX等のサービス提供",
    RoadMapDetailDate5:"2021.3Q",
    RoadMapDetailTitle5: "海外市場進出",
    RoadMapDetailContent5: "グローバルプラットフォーム発足式",
    RoadMapDetailDate6:"2021.4Q",
    RoadMapDetailTitle6: "グローバルサービス活性化",
    RoadMapDetailContent6: "海外企業提携及び決済プラットフォームオープン",
   
   // 7番目のセクション
    TeamMain: "BITTIGER TEAM",
    TeamSub1: "ビートタイガーを導いている我々のチーム員達です",
    TeamSub2: "ブロックチェーンの価値を作り上げていきます。",
    TeamSubMember01Name: "イ·イルウ",
    TeamSubMember01Job: "CEO",
    TeamSubMember01Hist01: "現) With Major CEO",
    TeamSubMember01Hist02: "前) PlanBit CEO",
    TeamSubMember01Hist03: "前）Qmaxschool理事",
    TeamSubMember02Name: "イ·ジェギョン(Justin) ",
    TeamSubMember02Job: "CFO",
    TeamSubMember02Hist01:"現）Bith a cfo",
    TeamSubMember02Hist02: "前）Bitbank理事",
    TeamSubMember02Hist03: "前）Cryptohub理事",
    TeamSubMember03Name: "Victor",
    TeamSubMember03Job: "CBO",
    TeamSubMember03Hist01: "現） Bithao CBO",
    TeamSubMember03Hist02: "前）VMC CSO",
    TeamSubMember03Hist03: "前） カイザアドバイザー",
    TeamSubMember04Name: "キム·スビン",
    TeamSubMember04Job: "CMO",
    TeamSubMember04Hist01: "現）bithaoマーケティングチーム長",
    TeamSubMember04Hist02: "前）カリフォルニア州カリペイアドバイザー",
    TeamSubMember05Name: "キム·ミンジュン",
    TeamSubMember05Job: "COO",
    TeamSubMember05Hist01: "前) Blobs COO",
    TeamSubMember05Hist02: "前) イッツアイティ代表取締役",
    TeamSubMember06Name: "キム·ミンウ",
    TeamSubMember06Job: "CTO",
    TeamSubMember06Hist01: "現）Blict 代表取締役",
    TeamSubMember06Hist02: "前) サムスンSDS研究員",
    TeamSubMember07Name: "チェ·ウヒョク",
    TeamSubMember07Job: "CIO",
    TeamSubMember07Hist01: "前 Blobs 理事",
    TeamSubMember08Name: "ゴ・ガビン",
    TeamSubMember08Job: "COO",
    TeamSubMember08Hist01: "現) ハイカンパニー代表取締役",
   
   //  8番目のセクション
    PartnersMain: "BITTIGER'S PARTNERS",
   
    //  9番目のセクション
    BTTalkMain:"TIGER WALLET",
    BTTalkSub1:"皆様の資産を安全に保管し、",
    BTTalkSub2: "ステーキングで収益を創出するタイガーウォレットを今から始めてください。",
    WhitePaperMain: "WHITE PAPER",
   
    //Footer
    AddressMain:
    "ソウル特別市江南区奉恩寺路317",
    CEOinfoMain:
    "事業者登録番号: 443-87-01788 | eメール: admin@bit-tiger.kr",
   };

export default StringJa;
   