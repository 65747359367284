const StringEn = {
    //common
    android: "android",
    iphone: "iPhone",
    bittiger: "Bit Tiger Talk",
    korean: "Korean",
    english: "English",
    chinese: "Chinese",
    japanese: "Japanese",
    vetnam: "Vietnamese",
    use: "Use",
    guide: "User Guide",
    franchise: "View franchises",
    prepare1: " The service will start on October 2nd.",
    prepare2: "The service will start on October 20th.",
  
    //body
    // first section
    PlatformMain: "Bittiger",
    PlatformSub1: "Blockchain Platform",
    PlatformSub2: "We realize a platform for global cryptocurrency based on blockchain.",
    PlatformButton: "Bit Tiger White Paper",
    PlatformSub3: "With global cryptocurrencies, Bit Tiger",
    PlatformSub4: "connects the offline world to one platform",
    PlatformSub5: "to realize a decentralized financial ecosystem.",
  
    // second section
    BusinessMain: "BUSINESS",
    BusinessSub: "Bit Tiger goes beyond the limits.",
    BusinessContent1: "With the development of IT technology, the world is connected, but there are limitations.",
    BusinessContent2: " Bit Ttiger beats this limit with three services.",
    BusinessDescTitle1: "Bittiger Multi-Wallet",
    BusinessDescContent1: "App that provides all services of Bit Tiger",
    BusinessDescTitle2: "Asset Management",
    BusinessDescContent2: "Operate and store cryptocurrency assets safely",
    BusinessDescTitle3: "Global Payment",
    BusinessDescContent3: "Pay that enables worldwide online or offline payment",
  
    // third section
    TalkMain: "Bittiger Multi-Wallet",
    TalkSub1: "Bit Tiger accepts various technology cryptocurrencies",
    TalkSub2: "and provides various businesses through one app",
    TalkContent1: "ㆍ Manage various coins such as Bitcoin and Ethereum in one wallet based on Bit Tiger",
    TalkContent2: "ㆍ P2P exchange from Tiger Wallet to other cryptocurrencies",
    TalkContent3: "ㆍ Expand the ecosystem by interlocking with various blockchain platforms such as Ethereum and EOS",
    TalkContent4: "ㆍ QR system for easy payment online and offline",
    TalkContent5: "ㆍ Simple remittance service linked with mobile phone contacts",
    TalkContent6: "ㆍ Commission optimization through blockchain transaction analysis",
      
    // fourth section
    SendEtcMain: "Asset Management",
    SendEtcSub: "Provides asset management service through real-time linkage with exchanges",
    SendEtcContent1: "ㆍ Real-time price check through API linkage with exchanges",
    SendEtcContent2: "ㆍ Real-time coin sales through API linkage with exchanges",
    SendEtcContent3: "ㆍ Exchange of coins based on real-time exchange market price",
    SendEtcContent4: "ㆍ Asset management through coin staking",
    SendEtcContent5: "ㆍ Safe asset storage through Multi-Sig application",
  
    // fifth section
    WorldMallMain: "Global Payment",
    WorldMallSub1: "Global Payment is a platform that allows the purchase of various products from all over the world online and offline.",
    WorldMallSub2: "Users can purchase goods or trade certified goods.",
    WorldMallBoxTitle1: "Tiger Market",
    WorldMallBoxContent1: "Purchase at franchises around the world affiliated with Tiger Wallet.",
    WorldMallBoxTitle2: "Tiger Exchange",
  WorldMallBoxContent2: "Trade in products that have been certified by blockchain.",
  
  // sixth section
    RoadMapMain: "Our roadmap",
    RoadMapSub1: "A team of experts in each field cooperates with companies in the blockchain field",
    RoadMapSub2: "to make a complete service quickly.",
    RoadMapDetailDate1: "3Q, 2020",
    RoadMapDetailTitle1: "Company establishment",
    RoadMapDetailContent1: "Hashone Co., Ltd. launch",
    RoadMapDetailDate2: "4Q, 2020",
    RoadMapDetailTitle2: "Exchange listing",
    RoadMapDetailContent2: "Token listing and wallet service beta open",
    RoadMapDetailDate3: "1Q, 2021",
    RoadMapDetailTitle3: "Blockchain Enterprise Partnership",
    RoadMapDetailContent3: "Build Tiger Wallet Alliance",
    RoadMapDetailDate4: "2Q, 2021",
    RoadMapDetailTitle4: "Wallet official open",
    RoadMapDetailContent4: "Provide Dual wallet, SNS, DEX, etc. services",
    RoadMapDetailDate5: "3Q, 2021",
    RoadMapDetailTitle5: "Enter Overseas Markets",
    RoadMapDetailContent5: "Global Platform Launch Ceremony",
    RoadMapDetailDate6: "4Q, 2021",
    RoadMapDetailTitle6: "Activate Global Service",
    RoadMapDetailContent6: "Open overseas business alliance and payment platform",
  
    // Seventh section
    TeamMain: "BIT TIGER TEAM",
    TeamSub1: "These are our team members who the Bit Tiger.",
    TeamSub2: "We create the value of the blockchain.",
    TeamSubMember01Name: "Lee, Ilwoo",
    TeamSubMember01Job: "CEO",
    TeamSubMember01Hist01: "Current) With Major CEO",
    TeamSubMember01Hist02: "Ex) PlanBit CEO",
    TeamSubMember01Hist03: "Ex) Qmaxschool Director",
    TeamSubMember02Name: "Lee, Jaekyung (Justin)",
    TeamSubMember02Job: "CFO",
    TeamSubMember02Hist01: "Current) Bithao CFO",
    TeamSubMember02Hist02: "Ex) Bitbank Director",
    TeamSubMember02Hist03: "Ex) Cryptohub Director",
    TeamSubMember03Name: "Victor",
    TeamSubMember03Job: "CBO",
    TeamSubMember03Hist01: "Current) Bithao CBO",
    TeamSubMember03Hist02: "Ex) VMC CSO",
    TeamSubMember03Hist03: " Ex) Kaiser adviser",
    TeamSubMember04Name: "Kim, Soobin",
    TeamSubMember04Job: "CMO",
    TeamSubMember04Hist01: "Current) Bithao Marketing Team Leader",
    TeamSubMember04Hist02: "Ex) Calipay Adviser, California",
    TeamSubMember05Name: "Kim, Minjun",
    TeamSubMember05Job: "COO",
    TeamSubMember05Hist01: "Ex) Blobs COO",
    TeamSubMember05Hist02: "Ex) It's IT CEO",
    TeamSubMember06Name: "Kim, Minwoo",
    TeamSubMember06Job: "CTO",
    TeamSubMember06Hist01: "Current) Blick CEO",
    TeamSubMember06Hist02: "Ex) Samsung SDS Researcher",
    TeamSubMember07Name: "Choi, Woohyuk",
    TeamSubMember07Job: "CIO",
    TeamSubMember07Hist01: "Ex) Blobs Director",
    TeamSubMember08Name: "Go, Gabin",
    TeamSubMember08Job: "COO",
    TeamSubMember08Hist01: "Current) Hyper Company CEO",
  
    // eighth section
    PartnersMain: "BITTIGER'S PARTNERS",
  
    // ninth section
    BTTalkMain: "TIGER WALLET",
    BTTalkSub1: "Start Tiger Wallet now",
    BTTalkSub2: "to keep your assets safe and generate profits through staking.",
    WhitePaperMain: "WHITE PAPER",
  
    //Footer
    AddressMain:
      "317, Bongeunsa-ro, Gangnam-gu, Seoul",
    CEOinfoMain:
      "Business Registration Number: 443-87-01788 | Email: admin@bit-tiger.kr",
  };
  export default StringEn;