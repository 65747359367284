import React from "react";
import { connect } from "react-redux";

class Footer extends React.Component {
  render() {
    const { language, string } = this.props;
    return (
      <div className="BT-FOOTER">
        <div className="BT-FT-Image">
          <img
            src={require("../img/logo.png")}
            style={{
              width: 170,
              height: 80,
            }}
          />
        </div>
        <div className="BT-FT-address">{string.AddressMain}</div>
        <div className="BT-FT-CEOinfo">{string.CEOinfoMain}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};
export default connect(mapStateToProps)(Footer);