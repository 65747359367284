import React from 'react';
// import './App.css';
import Layout from './components/Layout';

function App() {
  return (
     <div>
       <Layout></Layout>
     </div>
  );
}

export default App;
