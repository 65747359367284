import React from "react";
import { connect } from "react-redux";
import { stringReducer } from "../actions";

import AnchorLink from 'react-anchor-link-smooth-scroll'
class Header extends React.Component {
  setLanguage = (lang) => {
    this.props.setLanguage(lang)
  }
  render() {
    const { language, string } = this.props;
    return (
      <div className="BT-HEADER">
        
        <div className="BT-HD-right">
          <div className="BT-HD-right-container">
            <div className={`BT-HD-right-element ${language=='ko' ? 'color-blue' : ''}`} onClick={()=>this.props.setLanguage('ko')}>
              KO
            </div>
            <div className={`BT-HD-right-element ${language=='en' ? 'color-blue' : ''}`} onClick={()=>this.props.setLanguage('en')}>
              EN
            </div>
            <div className={`BT-HD-right-element ${language=='ja' ? 'color-blue' : ''}`} onClick={()=>this.props.setLanguage('ja')}>
              JA
            </div>
          </div>
          {/* <div className="BT-HD-right-container">
              <AnchorLink href='#business'>
            <div className="BT-HD-right-element2">
              BUSINESS
            </div>
              </AnchorLink>
              <AnchorLink href='#team'>
            <div className="BT-HD-right-element2">
              TEAM
            </div>
              </AnchorLink>
              <AnchorLink href='#partners'>
            <div className="BT-HD-right-element2">
              PARTNERS
            </div>
              </AnchorLink>
              <AnchorLink href='#whitepaper'>
            <div className="BT-HD-right-element2">
              WHITE PAPER
            </div>
              </AnchorLink>
          </div> */}
        </div >

        <div
          className="BT-HD-logoImage"
          style={{
            textAlign: "left",
          }}
        >
          <img
            src={require("../img/logo.png")}
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguage: lan => {
      dispatch(stringReducer(lan));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);