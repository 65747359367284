import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";

class Body extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupVisible: false
    }
  }
  render() {
    const { language, string } = this.props;
    return (
        <div className="BT-BODY">
          {/* 첫번째 섹션 */}
          <div className="BT-Platform">
            <div className="BT-Platform-firstText">
              {string.PlatformMain}
              <br />
              {string.PlatformSub1}
            </div>
            <div className="BT-Platform-secondText">{string.PlatformSub2}</div>
            <div className="BT-Platform-third">
              <a href="/BitTiger_Whitepaper_ko_v1.0.1015.pdf" target="_blank"><img src={require("../img/btn/btn_whitepaper.png")} /></a>
            </div>
            <div className="BT-Platform-fourthText">
              {string.PlatformSub3}
              <br />
              {string.PlatformSub4}
              <br />
              {string.PlatformSub5}
            </div>
          </div>

          {/* 두번째 섹션 */}
          <section id='business'></section>
          <div className="BT-Business">
            <div className="BT-Business-firstText">
              {string.BusinessMain}
              <br />
              ￣￣￣
            </div>
            <div className="BT-Business-SecondText text-title">{string.BusinessSub}</div>
            <div className="BT-Business-content text-subtitle">{string.BusinessContent1}</div>
            <div className="BT-Business-content text-subtitle">{string.BusinessContent2}</div>
            <div className="BT-Business-SecondImage">
              <div className="BT-Business-SecondImageContent-01">
                <div className="BT-Business-SecondImageContent-title">{string.BusinessDescTitle1}</div>
                <div className="BT-Business-SecondImageContent-content">{string.BusinessDescContent1}</div>
                {/* <div className="button-small color-purple" onClick={()=>{this.props.alert.show(string.prepare)}}>{string.android}</div>
                <div className="button-small color-purple desk" onClick={()=>{this.props.alert.show(string.prepare)}}>{string.iphone}</div> */}
              </div>
              <div className="BT-Business-SecondImageContent-02">
                <div className="BT-Business-SecondImageContent-title">{string.BusinessDescTitle2}</div>
                <div className="BT-Business-SecondImageContent-content">{string.BusinessDescContent2}</div>
                {/* <div className="button-small color-orange" onClick={()=>{this.props.alert.show(string.prepare)}}>{string.guide}</div> */}
              </div>
              <div className="BT-Business-SecondImageContent-03">
                <div className="BT-Business-SecondImageContent-title">{string.BusinessDescTitle3}</div>
                <div className="BT-Business-SecondImageContent-content">{string.BusinessDescContent3}</div>
                {/* <div className="button-small color-emerald" onClick={()=>{this.props.alert.show(string.prepare)}}>{string.franchise}</div> */}
              </div>
            </div>
          </div>

          {/* 세번째 섹션 */}
          <div className="BT-Talk">
            <div className="BT-Talk-firstText text-title">{string.TalkMain}</div>
            <div className="BT-Talk-secondText text-subtitle">
              {string.TalkSub1}
              <br />
              {string.TalkSub2}
            </div>
            <div className="BT-Talk-content text-detail">
              {string.TalkContent1}
              <br />
              {string.TalkContent2}
              <br />
              {string.TalkContent3}
              <br />
              {string.TalkContent4}
              <br />
              {string.TalkContent5}
              <br />
              {string.TalkContent6}
              <br />
            </div>
          </div>

          {/* 네번째 섹션 */}
          <div className="BT-SWM">
            <div className="BT-SE-firstText text-title">{string.SendEtcMain}</div>
            <div className="BT-SE-secondText text-subtitle">{string.SendEtcSub}</div>
            <div className="BT-SE-content text-detail">
              {string.SendEtcContent1}
              <br />
              {string.SendEtcContent2}
              <br />
              {string.SendEtcContent3}
              <br />
              {string.SendEtcContent4}
              <br />
              {string.SendEtcContent5}
              <br />
            </div>
            {/* <div className="BT-WDP-firstText text-title">{string.WorldPayMain}</div>
            <div className="BT-WDP-secondText text-subtitle">
              {string.WorldPaySub1}
              <br />
              {string.WorldPaySub2}
            </div>
            <div className="BT-WDP-content text-detail">
              {string.WorldPayContent1}
              <br />
              {string.WorldPayContent2}
              <br />
              {string.WorldPayContent3}
              <br />
              {string.WorldPayContent4}
              <br />
              {string.WorldPayContent5}
              <br />
              {string.WorldPayContent6}
              <br />
            </div>
            <div className="BT-MW-firstText text-title">{string.MultiWalletMain}</div>
            <div className="BT-MW-secondText text-subtitle">{string.MultiWalletSub}</div>
            <div className="BT-MW-content text-detail">
              {string.MultiWalletContent1}
              <br />
              {string.MultiWalletContent2}
              <br />
              {string.MultiWalletContent3}
              <br />
              {string.MultiWalletContent4}
              <br />
              {string.MultiWalletContent5}
              <br />
            </div> */}
          </div>

          {/* 다섯번째 섹션 */}
          <div className="BT-WM">
            <div className="BT-WM-firstText text-title">
              {string.WorldMallMain}
            </div>
            <div className="BT-WM-secondText text-subtitle">
              {string.WorldMallSub1}
              <br />
              {string.WorldMallSub2}
            </div>
            <div className="BT-WM-secondImageWrapper">
              <div className="BT-WM-secondImage">
                <div className="BT-WM-secondImage-icon"><img src={require('../img/sub/sub_icon_01.png')}/></div>
                <div className="BT-WM-secondImage-title">{string.WorldMallBoxTitle1}</div>
                <div className="BT-WM-secondImage-content">{string.WorldMallBoxContent1}</div>
                {/* <div className="button-small color-blue" onClick={()=>{this.props.alert.show(string.prepare)}}>가맹점보기</div> */}
              </div>
              <div className="BT-WM-secondImage">
                <div className="BT-WM-secondImage-icon"><img src={require('../img/sub/sub_icon_02.png')}/></div>
                <div className="BT-WM-secondImage-title">{string.WorldMallBoxTitle2}</div>
                <div className="BT-WM-secondImage-content">{string.WorldMallBoxContent2}</div>
                {/* <div className="button-small color-blue" onClick={()=>{this.props.alert.show(string.prepare)}}>구매하기</div> */}
              </div>
            </div>
          </div>

          {/* 여섯번째 섹션 */}
          <div className="BT-LM">
            <div className="BT-LM-firstText text-title">
              ￣￣￣
              <br />
              {string.RoadMapMain}
            </div>
            <div className="BT-LM-secondText text-subtitle">
              {string.RoadMapSub1}
              <br />
              {string.RoadMapSub2}
            </div>
            <div className="BT-LM-secondImageWrapper">
              <div className="BT-LM-secondImage">
                <div className="BT-LM-secondImage-date">{string.RoadMapDetailDate1}</div>
                <div className="BT-LM-secondImage-title">{string.RoadMapDetailTitle1}</div>
                <div className="BT-LM-secondImage-content">{string.RoadMapDetailContent1}</div>
              </div>
              <div className="BT-LM-secondImage">
                <div className="BT-LM-secondImage-date">{string.RoadMapDetailDate2}</div>
                <div className="BT-LM-secondImage-title">{string.RoadMapDetailTitle2}</div>
                <div className="BT-LM-secondImage-content">{string.RoadMapDetailContent2}</div>

              </div>
              <div className="BT-LM-secondImage">
                <div className="BT-LM-secondImage-date">{string.RoadMapDetailDate3}</div>
                <div className="BT-LM-secondImage-title">{string.RoadMapDetailTitle3}</div>
                <div className="BT-LM-secondImage-content">{string.RoadMapDetailContent3}</div>

              </div>
              <div className="BT-LM-secondImage">
                <div className="BT-LM-secondImage-date">{string.RoadMapDetailDate4}</div>
                <div className="BT-LM-secondImage-title">{string.RoadMapDetailTitle4}</div>
                <div className="BT-LM-secondImage-content">{string.RoadMapDetailContent4}</div>

              </div>
              <div className="BT-LM-secondImage">
                <div className="BT-LM-secondImage-date">{string.RoadMapDetailDate5}</div>
                <div className="BT-LM-secondImage-title">{string.RoadMapDetailTitle5}</div>
                <div className="BT-LM-secondImage-content">{string.RoadMapDetailContent5}</div>

              </div>
              <div className="BT-LM-secondImage">
                <div className="BT-LM-secondImage-date">{string.RoadMapDetailDate6}</div>
                <div className="BT-LM-secondImage-title">{string.RoadMapDetailTitle6}</div>
                <div className="BT-LM-secondImage-content">{string.RoadMapDetailContent6}</div>

              </div>
            </div>
          </div>

          {/* 아홉번째 섹션 */}
          <section id='whitepaper'></section>
          <div className="BT-TK">
            <div className="BT-TK-firstText text-title">{string.BTTalkMain}</div>
            <div className="BT-TK-secondText text-subtitle">
              {string.BTTalkSub1}
              <br />
              {string.BTTalkSub2}
            </div>
            <div className="BT-TK-secondImage">
              {/* <div className="button-large color-blue" onClick={()=>{this.props.alert.show(string.prepare)}}>{string.join}</div> */}
              <a href="https://play.google.com/store/apps/details?id=com.tigerwallet"><div className="button-large color-blue">{string.android}</div></a>
              <div className="button-large color-blue" onClick={()=>{this.props.alert.show(string.prepare2)}}>{string.iphone}</div>
            </div>

            <div className="BT-WP-firstText">
              ￣￣￣
              <br />
              {string.WhitePaperMain}
            </div>
            <div className="BT-WP-secondImage">
            <a href="/BitTiger_Whitepaper_ko_v1.0.1015.pdf" target="_blank"><div className="button-large color-blue width-150">{string.korean}</div></a>
            <a href="/BitTiger_Whitepaper_en_v1.0.1015.pdf" target="_blank"><div className="button-large color-blue width-150">{string.english}</div></a>
            <a href="/BitTiger_Whitepaper_cn_v1.0.1015.pdf" target="_blank"><div className="button-large color-blue width-150">{string.chinese}</div></a>
            <a href="/BitTiger_Whitepaper_ja_v1.0.1015.pdf" target="_blank"><div className="button-large color-blue width-150">{string.japanese}</div></a>
            <a href="/BitTiger_Whitepaper_vi_v1.0.1015.pdf" target="_blank"><div className="button-large color-blue width-150">{string.vetnam}</div></a>
            </div>
          </div>
          {this.state.popupVisible && (
            
          <div className="BT-popup-wrapper">
            <div className="BT-popup">
              <div className="BT-popup-close" onClick={()=>{this.setState({popupVisible: false})}}>
                <img src={require('../img/btn/close.png')} />
              </div>
              <div className="BT-popup-title">
              비트타이거 공지
              </div>
              <div className="BT-popup-content">
              안녕하세요 비트타이거 재단입니다. 
              2가지 공지를 드립니다. 
              </div>
              <div className="BT-popup-content-list">
              1. 드디어 내일오후 2시 포블게이트에 1900원에 상장합니다. <br/>
              많은 응원 부탁드립니다. 
              </div>
              <div className="BT-popup-content-list">
              2. 비트타이거 재단에서 소량 배포한 초기에어드랍 물량은 무기한 락입니다.  <br/>
              락물량이 거래소에 유입될 일은 없습니다. <br/>
              안심하세요~ <br/>
              (시중에 락물량이 풀린다는 소문이 있지만 사실이 아닙니다.)
              </div>
              <div className="BT-popup-content">
              비트타이거는 자체월렛을 보유하고있으며 차별화된 기술력으로 가산자산시장을 선도해 나가겠습니다. 
              </div>
              <div className="BT-popup-content">
              감사합니다. 
              </div>
            </div>
          </div>
          )}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};
export default withAlert()(connect(mapStateToProps)(Body));
